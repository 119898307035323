@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}
p {
  font-family: "Poppins", sans-serif;
}
.App {
  font-family: "Montserrat", sans-serif;
  background: rgb(255, 62, 51);
  background-image: linear-gradient(
    90deg,
    rgba(255, 62, 51, 1) 50%,
    rgba(255, 67, 56, 1) 49%,
    rgba(249, 89, 80, 1) 100%
  );
  background-image: linear-gradient(
      10deg,
      rgb(248, 158, 158) 20%,
      transparent 50%
    ),
    linear-gradient(-60deg, rgb(222 82 165) 30%, transparent 100%);
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100%;
  transition: all 0.2s ease-out;
  display: flex;
  flex-direction: column;
}
#container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: center;
  align-items: center;
  align-content: center;
}
#title-form {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
#form-container {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 3vh;
  width: 100%;
}
#form {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
#button-convert {
  display: none;
}
#form button {
  width: 150px;
  height: 50px;
  background-color: #4e9af1;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 600;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: auto;
  cursor: pointer;
}
#form p {
  font-size: 80%;
}
#form button:hover {
  background-color: #6dacf5;
}
button:disabled,
button[disabled="disabled"] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

#warning {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 400px;
  height: 50px;
  font-weight: 600;
  background-color: #ffcaca;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: rgb(65, 65, 65);
}
#infoVideo {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-weight: 600;
  background-color: #fcfcfc;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: rgb(65, 65, 65);
  width: 600px;
}
#infoTitle {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 800;
  background-color: #fcfcfc;
  outline: none;
  margin: 5px;
  color: rgb(65, 65, 65);
  word-break: break-all;
}
#infoThumb {
  opacity: 1;
  display: grid;
  justify-content: center;
  width: 400px;
  font-weight: 600;
  background-color: #fcfcfc;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: rgb(65, 65, 65);
}
#infoThumb img {
  border: rgba(0, 0, 0, 0.1);
}
#videoDownload {
  opacity: 1;
  display: none;
  flex-direction: column;
  align-items: center;
  align-content: center;
  font-weight: 200;
  background-color: #fcfcfc;
  font-size: 12px;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
}
#videoDownload button {
  width: 150px;
  height: 50px;
  background-color: #f14e56;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 600;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: auto;
  cursor: pointer;
}
#form input {
  background-image: url(https://cdn4.iconfinder.com/data/icons/round-mobile-ui-set/512/letter-message-email-mail-20.png);
  background-image: url(https://cdn4.iconfinder.com/data/icons/round-mobile-ui-set/512/letter-message-email-mail-20.png);
  background-position: 10px center;
  background-repeat: no-repeat;
  max-height: 60px;
  min-height: 60px;
  width: 600px;
  font-weight: 200;
  background-color: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  font-size: 15px;
  outline: none;
  margin: 5px;
  border: none;
  border-radius: 5px;
  color: rgb(65, 65, 65);
  padding-left: 40px;
  padding-right: 10px;
}
#title-form {
  padding: 20px;
  font-size: 200%;
}
#title-form p {
  font-weight: 1;
}
#slung h1 {
  display: none;
}
#title-form h2 {
  align-items: center;
}
#detailVideo {
  width: auto;
  word-break: break-all;
}
#faq {
  color: #fff;
  padding: 5px;
  font-size: 100%;
  background: rgba(68, 68, 68, 0.2);
  border-radius: 5px;
  width: 600px;
}
#form-faq {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5vh;
  width: 100%;
}
#advertising-left {
  display: flex;
  justify-content: right;
  max-width: 100%;
  overflow: hidden;
}
#advertising-right {
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: left;
}
#main-footer {
  margin-top: auto;
  left: 0;
  bottom: 0;
  padding-bottom: 1rem;
  display: flex;
  width: 100%;
  font-size: 75%;
  justify-content: center;
  align-items: center;
  color: #fff;
}
#main-footer a {
  text-decoration: none;
  color: #4e9af1;
}
#loading {
  margin: 5px;
  display: none;
}
@media only screen and (max-width: 999px) {
  #advertising-right {
    width: 90%;
  }
  #advertising-left {
    width: 90%;
  }
  #infoVideo {
    width: 90%;
  }
  #form input {
    width: 100%;
  }
  #warning {
    width: 100%;
  }
  #faq {
    font-size: 90%;
    width: 90%;
  }
}
@media only screen and (max-width: 599px) {
  #infoVideo {
    width: 80%;
  }
  #form input {
    width: 100%;
  }
  #warning {
    width: 100%;
  }
  #faq {
    font-size: 80%;
    width: 80%;
  }
  #advertising-right > div {
    display: none;
  }
  #advertising-left > div {
    display: none;
  }
}

